// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-base-index-tsx": () => import("./../../../src/pages/base/index.tsx" /* webpackChunkName: "component---src-pages-base-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-graphics-index-tsx": () => import("./../../../src/pages/graphics/index.tsx" /* webpackChunkName: "component---src-pages-graphics-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-project-pages-one-tsx": () => import("./../../../src/projectPages/one.tsx" /* webpackChunkName: "component---src-project-pages-one-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/categoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-graphic-template-tsx": () => import("./../../../src/templates/graphicTemplate.tsx" /* webpackChunkName: "component---src-templates-graphic-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/projectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-studio-template-tsx": () => import("./../../../src/templates/studioTemplate.tsx" /* webpackChunkName: "component---src-templates-studio-template-tsx" */)
}

