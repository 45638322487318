import cx from "classnames";
import React, { ReactElement, useEffect, useState } from 'react';
import FaviconLoader from "../components/favicon";
import Header from '../components/header/header';
import HeaderMobile from '../components/header/header-mobile';
import HeaderClosed from "../components/header/headerClosed";
import HeaderStudio from "../components/header/headerStudio";
import useWindowDimensions from '../components/useWindowDimensions';
import { usePageContext } from "../contexts/pageContext";
import useSiteMetadata from '../static-queries/useSiteMetadata';
import layoutStyles from "./layout.module.scss";

export interface LayoutProps {
  bgColor?: string,
  children: ReactElement[] | ReactElement
  hideHeader?: boolean
}

export default function Layout({
  children,
  hideHeader,
}: LayoutProps): ReactElement {
  const { title, description } = useSiteMetadata();
  const [isLoaded, setIsLoaded] = useState(false); // State to track if the component has mounted
  const { height, width } = useWindowDimensions();
  const { isSideBarShown, setIsSideBarShown, pageType} = usePageContext()

  useEffect(() => {
    setIsLoaded(true); // Set to true once the component has mounted
  }, []); 

  // Render logic that depends on window dimensions and component being mounted
  const renderHeader = () => {
    if (!isLoaded) return null; // Don't render anything until component has mounted
    return (
      pageType === "pdf" ? <></> :
      pageType === "studio" ? <HeaderStudio /> :
      width > 820 && isSideBarShown ? <Header /> :
     width > 820 && !isSideBarShown ? <HeaderClosed/> :
       <HeaderMobile />
      );
  };

  return (
    <article className="relative">
      <FaviconLoader />
      {renderHeader()}
      <section className={cx(
        pageType === "studio" && layoutStyles.baseStudio, 
        isSideBarShown && layoutStyles.base, 
        "flex flex-col z-10")}>
        {children}
      </section>
    </article>
  );
}