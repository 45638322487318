/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.scss';

import React from 'react';
import { PageTypeProvider } from './src/contexts/pageContext';

export const wrapRootElement = ({ element }) => {
  return <PageTypeProvider>{element}</PageTypeProvider>;
};
