import cx from "classnames";
import { graphql, Link, StaticQuery } from 'gatsby';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import useSiteMetadata from '../../static-queries/useSiteMetadata';
import Category from "../Category/Category";
import ColorCircle from "../ColorCircle";
import useWindowDimensions from '../useWindowDimensions';
import headerStyles from "./headerMobile.module.scss";

const HeaderMobileComponent = ({data}): React.ReactElement => {
  const {menu} = useSiteMetadata();
  const {height, width} = useWindowDimensions();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selected, setSelected] = useState<boolean>(false);
  const [isMenuShown, setIsMenuShown] = useState(false);
// Determine the classes for the menu section
const [isScrolled, setIsScrolled] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    // Check if the page has been scrolled more than 50px
    setIsScrolled(window.scrollY > 50);
  };

  // Add the event listener
  window.addEventListener('scroll', handleScroll);

  // Clean up the event listener 
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  const renderSymbol = (type: string) => {
    return type === "blog" ? "☱" :
      type === "project" ? "☆" :
      type === "graphic" ? "⛋" : "·"
  }

  return (
    <nav className={
      cx("flex flex-col fixed top-0 left-0 z-50 w-full bg-transparent p-4", isMenuShown && "backdrop-blur bg-white bg-opacity-20")}>
      {/*<div className={cx(headerStyles.work,"text-xs absolute")}> 🚧 (Work in progress), a lot more to upload</div>*/}
      <div className="flex justify-between items-center ">

        <Link
          to={'/base'}
          onClick={() => {
            setIsMenuShown(false)
          }}
          className={cx("flex items-center w-10 h-10 pl-2", isScrolled && !isMenuShown && "bg-white rounded-full shadow-lg p-2 mr-2")}
        >
            <ColorCircle size={25}/>
        </Link>

        <div className={cx("flex items-center gap-2 transition-all duration-300", isScrolled && !isMenuShown && "bg-white rounded-2xl shadow-lg px-4 py-2")}>       
         <Link onClick={() => {
            setIsMenuShown(false)
          }} className={"flex align-center"} to={"/projects"}>
          <p className="font-charisma text-sm pr-2 py-1 rounded-md">☆</p>
        </Link>
        <Link onClick={() => {
            setIsMenuShown(false)
          }} className={"flex align-center"} to={"/blog"}>
          <p className="font-charisma text-sm pr-2 py-1 rounded-md ">☱</p>
        </Link>
        <Link onClick={() => {
            setIsMenuShown(false)
          }} className={"flex align-center"} to={"/graphics"}>
          <p className="font-charisma text-sm py-1 rounded-md ">⛋</p>
        </Link>
      </div>


{/* HAMBURGUER BUTTON */}
        <div className={cx("flex items-center gap-2 transition-all duration-300", isScrolled && !isMenuShown && "bg-white rounded-2xl shadow-lg px-4 py-3")}>       
        <Link to="/contact">
          <div>
          <img height={15} width={15} src='/images/mail.svg'/>
          </div>
        </Link>

      </div>
      </div>

      {isMenuShown && <>
      <section className={cx(
    !isMenuShown ? headerStyles.menuEnter : headerStyles.menuEnterActive, "mt-4 w-full bg-white rounded-xl")}>
  <div className={cx("text-sm flex flex-col overflow-y-scroll w-full py-2 pr-2 rounded-xl border-t-2  border-b-2 border-gray-100", headerStyles.listContainers)}>

    {data?.all?.edges?.map((entry: any, index: number) => {
      // console.log(entry);
      return (
        <Link
          onClick={() => {
            setIsMenuShown(false)
          }}
          key={index}
          to={"/"+ entry.node?.fields.type +"/" + entry.node?.fields.slug}
          className={cx(
            "block rounded-lg w-full text-gray-700 transition-all hover:text-gray-800 hover:bg-bg_blue py-2 px-2 font-charisma"
          )}
        >
          <div className="filter grayscale hover:grayscale-0">
            <div className={"flex items-center align-center h-4"}>
              <span className={"mr-1 text-2xs text-gray-600"}>{renderSymbol(entry.node?.fields.type)}</span>
              <span
                className="text-2xs text-gray-500">
                {moment(entry.node.frontmatter.date).format("ll")}
              </span>
              <div className={"flex mx-2"}>
                <div className={"flex"}>
                  {entry.node?.frontmatter.categories?.map((cat: string) => {
                    return <Category isClickable={false} key={cat} size={8} category={cat}/>

                  })}
                </div>
              </div>
            </div>
            <p className="text-sm tracking-wide align-center flex">
              {entry.node?.frontmatter.title}</p>
          </div>
        </Link>
      )
    })}

  </div>
  {/* <CatList removeMenu/> */}

<div className={cx("flex w-full  justify-center h-full items-center gap-2 p-3", headerStyles.links)}>

<Link to={"/contact"} className="flex items-center opacity-70 hover:opacity-100 font-charisma text-gray-500" onClick={() => {
    width <= 821 && setIsMenuShown(false)
  }}>
  <img height={15} width={15} src='/images/mail.svg'/>
</Link>
<a onClick={() => {
    width <= 821 && setIsMenuShown(false)
  }} className={"cursor-pointer flex items-center h-4 opacity-70 hover:opacity-100 transition-all"} href={"https://github.com/gordo-labs"}>
  <img height={15} width={15} src='/images/github.svg'/>
</a>
<a onClick={() => {
    width <= 821 && setIsMenuShown(false)
  }} className={"cursor-pointer flex items-center opacity-70 hover:opacity-100 h-4 transition-all"} href={"https://twitter.com/gordo_design"}>
  <img className='' height={15} width={15} src='/images/x.svg'/>
</a>
</div>
    </section></>}
      
    </nav>

  );
};

export default function HeaderMobile(props: any) {
  return (
    <StaticQuery<GatsbyTypes.BuildMenuMobileQuery>
      query={graphql`
              query BuildMenuMobile {
                             
                all: allMarkdownRemark(
                  filter: {fileAbsolutePath: {regex: "/graphics|projects|posts/.*\\.md$/"}}
                  limit: 2000, 
                  sort: {fields: frontmatter___date, order: DESC}
                ) {
                  edges {
                    node {
                      fields {
                        slug
                        type
                      }
                      id
                      frontmatter {
                        date
                        title
                        categories
                        author
                      }
                    }
                  }
                }
              }
      `}
      render={(data: any) => <HeaderMobileComponent data={data} {...props}/>}
    />
  )
}



