import React, {useState, useEffect} from 'react';
import Canvas from "./Canvas";

type IFaviconLoaderProps = {}
const isBrowser = () => typeof window !== "undefined"
const FaviconLoader: React.FC<IFaviconLoaderProps> = ({}) => {
  const [canvasState, setCanvas] = useState(null);
/*  if (isBrowser()) {
    var canvas = document.createElement('canvas');
    canvas.width = 16;
    canvas.height = 16;
    var ctx = canvas.getContext('2d');
    var img = new Image();
    ctx.drawImage(img, 0, 0);
    ctx.fillStyle = "#426ba8";
    ctx.fillRect(10, 7, 6, 8);
    ctx.fillStyle = '#FFFFFF';
    ctx.font = 'bold 10px sans-serif';
    // ctx.fillText('2', 10, 14);

    img.src = '/favicon.ico';
    img.onload = function () {
      var link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = canvas.toDataURL("image/x-icon");
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }*/
  useEffect(() => {
    // setInterval()
  }, []);

  return <>
    <div className="hidden">
      <Canvas/>
    </div>
  </>
}

FaviconLoader.defaultProps = {}

export default FaviconLoader;
