import React, { useEffect, useRef, useState } from 'react';

type ICanvasProps = {}

const Canvas: React.FC<ICanvasProps> = (props) => {
  const canvasRef = useRef(null);
  const intervalRef = useRef(null);
  const [color, setColor] = useState(`rgb(0,0,0)`);

  const randNum = () => Math.floor(Math.random() * 256);

  const changeColor = () => {
    setColor(`rgb(${randNum()},${randNum()},${randNum()})`);
  };

  const buildFavIcon = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = 100;
    canvas.height = 100;

    // Use the state color for filling
    ctx.fillStyle = color;

    // Draw a circle
    ctx.beginPath();
    ctx.ellipse(50, 50, 45, 45, Math.PI / 4, 0, 2 * Math.PI);
    ctx.fill();

  // Function to create a darker color
  const darkenColor = (col: any, amount: number) => {
    const [r, g, b] = col.match(/\d+/g).map(Number);
    return `rgb(${Math.max(r - amount, 0)}, ${Math.max(g - amount, 0)}, ${Math.max(b - amount, 0)})`;
  };

  // Add a darker border of the same color
  ctx.strokeStyle = darkenColor(color, 30); // Darken by 30 units
      ctx.lineWidth = 10;
      ctx.stroke();

    // Update the favicon
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = canvas.toDataURL("image/x-icon");

    const head = document.getElementsByTagName('head')[0];
    const existingLink = document.querySelector('link[rel="icon"]');

    if (existingLink) {
      head.removeChild(existingLink);
    }

    head.appendChild(link);
  };

  useEffect(() => {
    buildFavIcon();

    intervalRef.current = setInterval(() => {
      changeColor();
    }, 125);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, []);

  useEffect(() => {
    buildFavIcon();
  }, [color]); // Re-run this effect when color changes

  return (
    <>
      <canvas className="fixed top-0 right-0 z-50 hidden" ref={canvasRef} {...props} />
    </>
  );
}

Canvas.defaultProps = {}

export default Canvas;
