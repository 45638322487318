import { useFrame } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";

type IColorCircleProps = {
  size?: number
}

const CircleContainer = styled.div<IColorCircleProps>`
  height: ${props => props.size && props.size + "px"};
  width: ${props => props.size && props.size + "px"};
  border-radius: ${props => props.size && props.size * 2 + "px"};
  //box-shadow: 0px 2px 10px white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: 2px solid rgba(0, 0, 0, 0.15);
`

const ColorCircle: React.FC<IColorCircleProps> = (props) => {
  const randNum = () => Math.floor(Math.random() * 256);
  const [color, setColor] = useState(
    `rgb(${randNum()},${randNum()},${randNum()})`
  );

  const changeColor = () => {
    setColor(`rgb(${randNum()},${randNum()},${randNum()})`);
  }

  useEffect(() => {
    const colorTimer = setInterval(changeColor, 125);
    return () => {
      clearInterval(colorTimer)
    };
  }, []);

  function Box(props) {
    // This reference will give us direct access to the THREE.Mesh object
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => (mesh.current.rotation.x += 0.01))
    // Return the view, these are regular Threejs elements expressed in JSX
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.5 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}>
        <boxGeometry args={[1, 1, 1]}/>
        <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'}/>
      </mesh>
    )
  }

  return (
    <React.Fragment>
      <CircleContainer {...props}
        style={{backgroundColor: color}}>
      </CircleContainer>
    </React.Fragment>
  )
}

ColorCircle.defaultProps = {
  size: 50
}

export default ColorCircle
