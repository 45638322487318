import cx from "classnames";
import { graphql, Link, StaticQuery } from 'gatsby';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import useSiteMetadata from '../../static-queries/useSiteMetadata';
import Category from "../Category/Category";
import ColorCircle from "../ColorCircle";
import useWindowDimensions from '../useWindowDimensions';
import headerStyles from "./header.module.scss";


const HeaderComponent = ({data}): React.ReactElement => {
  const {menu} = useSiteMetadata();
  const {height, width} = useWindowDimensions();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selected, setSelected] = useState<boolean>(false);
  const [isMenuShown, setIsMenuShown] = useState(false);

  useEffect(() => {
    width <= 821 ? setIsMenuShown(false) : setIsMenuShown(true);
    return () => {
    };
  }, []);

  const renderSymbol = (type: string) => {
    return type === "blog" ? "☱" :
      type === "project" ? "☆" :
      type === "graphic" ? "⛋" : "·"
  }

  return (

    <nav className={
      cx("flex flex-col h-screen py-2 px-5 fixed top-0 z-50 w-full bg-transparent border-gray-200 border-b-0 border-r max-w-xs")}>
      <div className="flex justify-between items-center">

        <Link

          to={'/'}
          className="flex items-center w-10 h-10 pl-2"
        >
            <ColorCircle size={20}/>
        </Link>

        {/* MENU DESKTOP */}
        <div className={cx("flex items-center gap-2 mr-3")}>
        <Link to={"/contact"} className="flex items-center transition-all opacity-50 hover:opacity-100 font-charisma text-gray-500" onClick={() => {
          }}>
          <img height={15} width={15} src='/images/mail.svg'/>
        </Link>
        <a className={"cursor-pointer flex items-center h-4 opacity-50 hover:opacity-100 transition-all"} target="_blank" href={"https://github.com/gordo-labs"}>
          <img height={15} width={15} src='/images/github.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://twitter.com/gordo_design"}>
          <img className='' height={15} width={15} src='/images/x.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://warpcast.com/gordo"}>
          <img className='' height={15} width={15} src='/images/farcaster.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://discord.gg/XMbCd8bgNQ"}>
          <img className='' height={15} width={15} src='/images/discord.svg'/>
        </a>
      </div>


      </div>

      {<div className='flex md:h-full'>
          <section className={' md:h-full'}>
          <div className="w-full block flex-grow lg:flex lg:w-auto md:h-full flex-col">
            {/* <div className="text-center"><p className="text-xs text-gray-400 py-1 pr-2 pl-2 tracking-widest">Everything</p></div> */}
        <div className={cx("text-sm flex flex-col overflow-y-scroll w-full bg-white mt-2 py-2 pl-2 rounded-xl", headerStyles.listContainers)}>
          {data?.all?.edges?.map((entry: any, index: number) => {
            // console.log(entry);
            return (
<div
            key={index}
            className="pr-2"
          >
            <Link
              to={`/${entry.node.fields.type}/${entry.node.fields.slug}`}
              onClick={() => setIsMenuShown(false)}
              className="block rounded-lg text-gray-700 bg-white hover:text-gray-800 hover:bg-bg_blue py-2 px-2 w-full transition-colors duration-300 ease-in-out font-charisma"
            >
                <div className="filter grayscale hover:grayscale-0">
                  <div className={"flex items-center align-center h-4"}>
                    <span className={"mr-1 text-2xs text-gray-600"}>{renderSymbol(entry.node?.fields.type)}</span>
                    <span
                      className="text-2xs text-gray-500">
                      {moment(entry.node.frontmatter.date).format("ll")}
                    </span>
                    <div className={"flex mx-2"}>
                      <div className={"flex"}>
                        {entry.node?.frontmatter.categories?.map((cat: string) => {
                          return <Category isClickable={false} key={cat} size={8} category={cat}/>

                        })}
                      </div>
                    </div>
                  </div>
                  <p className="text-sm tracking-wide align-center flex">
                    {entry.node?.frontmatter.title}</p>
                </div>
              </Link>
              </div>
            )
          })}

        </div>
      </div> 

          </section>
      </div>
      }

    </nav>

  );
};


export default function Header(props: any) {
  return (
    <StaticQuery<GatsbyTypes.BuildMenuQuery>
      query={graphql`
              query BuildMenu {        
                all: allMarkdownRemark(
                  filter: {fileAbsolutePath: {regex: "/graphics|projects|posts/.*\\.md$/"}}
                  limit: 2000, 
                  sort: {fields: frontmatter___date, order: DESC}
                ) {
                  edges {
                    node {
                      fields {
                        slug
                        type
                      }
                      id
                      frontmatter {
                        date
                        title
                        categories
                        author
                      }
                    }
                  }
                }
              }
      `}
      render={(data: any) => <HeaderComponent data={data} {...props}/>}
    />
  )
}
