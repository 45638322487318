import React, { createContext, ReactNode, useContext, useState } from 'react';

type PageType = 'default' | 'project' | 'cv' | 'studio' | 'pdf';

interface PageTypeContextType {
  pageType: PageType;
  isSideBarShown: boolean;
  setPageType: (pageType: PageType) => void;
  setIsSideBarShown: (bool: boolean) => void;
}

const PageTypeContext = createContext<PageTypeContextType | undefined>(undefined);

export const PageTypeProvider = ({ children }: { children: ReactNode }) => {
  const [pageType, setPageType] = useState<PageType>('default');
  const [isSideBarShown, setIsSideBarShown] = useState<boolean>(false);

  return (
    <PageTypeContext.Provider value={{ pageType, setPageType, isSideBarShown, setIsSideBarShown }}>
      {children}
    </PageTypeContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PageTypeContext);
  if (!context) {
    throw new Error('usePageType must be used within a PageTypeProvider');
  }
  return context;
};
