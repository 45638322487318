import { navigate } from 'gatsby';
import React, {
  useEffect,
  useState,
} from 'react'; // we need this to make JSX compile
import styled from 'styled-components';
import categoriesFile from '../../../content/data/categories.json';

type ICategoryProps = {
  category: string,
  size?: number,
  tooltip?: boolean,
  text?: boolean,
  boring?: boolean,
  isClickable?: boolean,
};

const CategoryCont =
  styled.div <
  ICategoryProps >
  `
  height: ${(props) =>
    props.size && props.size + 'px'};
  width: ${(props) =>
    props.size && props.size + 'px'};
  border-radius: ${(props) =>
    props.size &&
    props.size * 2 + 'px'};
  margin: ${(props) =>
    props.size &&
    props.size / 5 + 'px'};
  cursor: pointer;
`;

const CatTooltip =
  styled.div <
  ICategoryProps >
  `
  position: absolute;
  top: ${(props) =>
    props.size &&
    props.size * 1.5 + 'px'};
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  font-weight: bold;
`;

const Category: React.FC<
  ICategoryProps
> = (props) => {
  const {
    category,
    tooltip,
    size,
    isClickable,
    text,
    boring,
  } = props;
  const [cat, setCat] = useState(
    categoriesFile.categories.find(
      (cat) => cat.name === category
    )
  );
  const [
    isTooltipVisible,
    setIsTooltipVisible,
  ] = useState(false);

  useEffect(() => {
    // console.log('color', cat?.color);
  }, []);

  return (
    <div
      className="mb-2 md:mb-0"
      onClick={() =>
        isClickable &&
        navigate(
          '/category/' + cat?.name
        )
      }>
      <div className={'relative'}>
        {isTooltipVisible && tooltip && (
          <CatTooltip
            style={{
              backgroundColor:
                cat?.color,
            }}
            className="cat-tooltip font-charisma z-50 font-light rounded-2xl shadow-lg py-1 px-3 text-xs tracking-wider text-black whitespace-pre"
            {...props}>
            {cat?.name}
          </CatTooltip>
        )}
        {!text && (
          <CategoryCont
            onMouseOver={() =>
              setIsTooltipVisible(true)
            }
            onMouseLeave={() =>
              setIsTooltipVisible(false)
            }
            style={{
              backgroundColor:
                cat?.color !== ''
                  ? cat?.color
                  : 'grey',
              border:
                '1px solid ' +
                  cat?.color !==
                ''
                  ? cat?.color
                  : 'black',
            }}
            {...props}></CategoryCont>
        )}

        {text && (
          <div
            style={{
              backgroundColor:
                !boring && cat?.color !== ''
                  ? cat?.color
                  : 'white',
              border:
                '1px solid ' +
                  cat?.color !==
                ''
                  ? cat?.color
                  : 'black',
            }}
            className="cursor-pointer whitespace-pre hover:shadow cat-tooltip mr-2 rounded-2xl py-1 px-3 my-1 text-2xs text-gray-900 hover:text-black"
            {...props}>
            {boring && "#"}{cat?.name}
          </div>
        )}
      </div>
    </div>
  );
};

Category.defaultProps = {
  size: 10,
  category: 'default',
  isClickable: true,
};

export default Category;
