import cx from "classnames";
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import useSiteMetadata from '../../static-queries/useSiteMetadata';
import ColorCircle from "../ColorCircle";
import useWindowDimensions from '../useWindowDimensions';


const HeaderComponentClosed = ({data}): React.ReactElement => {
  const {menu} = useSiteMetadata();
  const {height, width} = useWindowDimensions();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selected, setSelected] = useState<boolean>(false);
  const [isMenuShown, setIsMenuShown] = useState(false);

  useEffect(() => {
    width <= 821 ? setIsMenuShown(false) : setIsMenuShown(true);
    return () => {
    };
  }, []);

  const renderSymbol = (type: string) => {
    return type === "blog" ? "☱" :
      type === "project" ? "☆" :
      type === "graphic" ? "⛋" : "·"
  }

  return (

    <nav className={
      cx("flex py-2 px-2 fixed top-0 z-50 bg-transparent")}>
      <div className="flex flex-col justify-center items-center">


        {/* MENU DESKTOP */}
        <div className={cx("flex flex-col justify-items-center items-center gap-2")}>
        <Link

          to={'/base'}
          className="flex items-center justify-center w-10 h-10"
        >
            <ColorCircle size={20}/>
        </Link>
        <Link to={"/contact"} className="flex items-center transition-all opacity-50 hover:opacity-100 font-charisma text-gray-500" onClick={() => {
          }}>
          <img height={15} width={15} src='/images/mail.svg'/>
        </Link>
        <a className={"cursor-pointer flex items-center h-4 opacity-50 hover:opacity-100 transition-all"} target="_blank" href={"https://github.com/gordo-labs"}>
          <img height={15} width={15} src='/images/github.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://twitter.com/gordo_design"}>
          <img className='' height={15} width={15} src='/images/x.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://warpcast.com/gordo"}>
          <img className='' height={15} width={15} src='/images/farcaster.svg'/>
        </a>
        <a className={"cursor-pointer flex items-center opacity-50 hover:opacity-100 h-4 transition-all"} target="_blank" href={"https://discord.gg/XMbCd8bgNQ"}>
          <img className='' height={15} width={15} src='/images/discord.svg'/>
        </a>
      </div>

      </div>

    </nav>

  );
};


export default function HeaderClosed(props: any) {
  return (
    <HeaderComponentClosed {...props}/>
  )
}
